import React from "react"
import marked from "marked"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import Layout from "../components/layout"
import { AnimatePresence } from "framer-motion"
import AboutCard from "../components/about-card"
import InitialTransition from "../components/transition"

export const pageQuery = graphql`
  query AboutQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt(pruneLength: 140)
      frontmatter {
        title
      }
    }
  }
`

export const AboutPageContent = ({ title, html, body }) => {
  const source = marked(html || body)
  return (
    <div className="about-page">
      <h1 dangerouslySetInnerHTML={{ __html: title }} />
      <article dangerouslySetInnerHTML={{ __html: source }} />
    </div>
  )
}
const AboutPage = ({ data }) => {
  const { markdownRemark } = data
  const { frontmatter, html, excerpt } = markdownRemark

  const hold =
    typeof window !== "undefined" && window.location.hash === "#contact"
      ? false
      : true

  return (
    <AnimatePresence exitBeforeEnter>
      {hold && <InitialTransition id="about" />}
      <Layout className="page">
        <SEO title="About Kennedy Iyeh" description={excerpt} />
        <AboutPageContent title={frontmatter.title} html={html} />
        <AboutCard />
      </Layout>
    </AnimatePresence>
  )
}

export default AboutPage
