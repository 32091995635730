import React from "react"
import test from "../../static/assets/tools.svg"
import fist from "../../static/assets/fist-bump.png"

export const AboutCard = ({ data }) => {
  return (
    <section id="contact" className="about-card ">
      <div className="card-content">
        <p className="tagline">You’ve come too far to go back now</p>

        <h2 className="header">
          Got an interesting project? Let’s make{" "}
          <span className="decorated">magic</span> together!{" "}
          <span className="fist">
            <img src={fist} alt="emoji fist bump" />
          </span>
        </h2>

        <p className="content">
          It would be my pleasure to get on a call with you or chat via email
          about your venture, projects or startup. Make that move, you deserve
          the best.
        </p>

        <div className="cta">
          <a
            href="mailto:iyehkennedy@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span role="img" aria-label="mailbox">
              📬
            </span>{" "}
            Send that email
          </a>
          <a
            href="https://calendly.com/iyehkennedy/15min?month=2021-01"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span role="img" aria-label="call me">
              🤙🏽
            </span>{" "}
            Schedule a call
          </a>
        </div>
      </div>
      <div className="tools">
        <img src={test} alt="tools figma adobe-xd illustator sketch" />
      </div>
    </section>
  )
}

export default AboutCard
